<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="50"
        hide-default-footer
    >
    <template v-slot:item.actions="{ index }">
        <v-btn @click="handleRemoveRow(index)" color="error" small>Löschen</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    removeRow: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleRemoveRow(index) {
      this.removeRow(index); 
    },
  },
};
</script>

<style>

</style>